import React from "react";

import ImageOne from "../assets/images/image.webp";

import styles from "../styles/one_app.module.css";

export default function OnAppComponent() {
  return (
    <section className={styles["one__app_section"]}>
      <div className={styles["container"]}>
        <div className={styles["one__app_content"]}>
          <div className={styles["flex__content"]}>
            <h1 id={styles["mobile"]}>
              One platform for all your cross-border revenues and payments{" "}
            </h1>
            <p id={styles["mobile"]}>
              Our platform streamlines the entire payment process, from
              initiation to settlement, enabling businesses to send and receive
              payments across borders with unprecedented speed and efficiency.
            </p>
            {/* <div className={styles["flex__left_content"]}>
              <img src={ImageOne} alt="" />
            </div> */}
            <div className={styles["flex__right_content"]}>
              <h1>
                One platform for all your cross-border revenues and payments{" "}
              </h1>
              <p>
                We believe in keeping things simple, fast, and low-cost. Just a
                few taps and your money can be on its way, without breaking the
                bank. Get better exchange rates, full transparency, and easy
                tracking of your money.​
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
