import React from "react";

import FastImage from "../assets/icons/fast.png";
import LowCostImage from "../assets/icons/low-cost.webp";
import MultiChainImage from "../assets/icons/multi-currency.png";
import TransparencyImage from "../assets/icons/secure.webp";
import ComplianceImage from "../assets/icons/transparent.webp";
import EscrowBasedImage from "../assets/icons/reward.webp";

import styles from "../styles/why_cbdcx.module.css";

export default function WhyCBDCXComponent() {
  return (
    <section id="why-cbdcx" className={styles["cbdcx__section"]}>
      <div className={styles["container"]}>
        <div className={styles["cbdcx__container"]}>
          <h1>Why CBDCX</h1>
          <p>
          We are building an industry-first cross-border payments infrastructure.
          </p>
          <div className={styles["grid__container"]}>
            <div className={styles["grid__content"]}> 
              <img src={FastImage} width={52} height={52} />
              <h1>Instant Settlement</h1>
              <p>Say goodbye to waiting days for payments to clear. With CBDCX, transactions settle instantly, providing businesses with immediate access to funds.</p>
            </div>
            <div className={styles["grid__content"]}> 
              <img src={MultiChainImage} width={52} height={52} />
              <h1>Multi-Currency Support</h1>
              <p>Whether it's currencies or digital assets, our platform supports a wide range of currencies, providing businesses with the flexibility they need to operate globally.</p>
            </div>
            <div className={styles["grid__content"]}> 
              <img src={TransparencyImage} width={52} height={52} />
              <h1>Transparency and Security</h1>
              <p>Built on blockchain technology, our platform ensures transparency and security, allowing businesses to track the status of their transactions in real-time.</p>
            </div>
            <div className={styles["grid__content"]}> 
              <img src={ComplianceImage} width={52} height={52} />
              <h1>Compliance</h1>
              <p>We adhere to the highest regulatory standards, including anti-money laundering (AML) and know your customer (KYC) requirements and <br />regulations.</p>
            </div>
            <div className={styles["grid__content"]}> 
              <img src={EscrowBasedImage} width={52} height={52} />
              <h1>Escrow-Based</h1>
              <p>The platform incorporates a new escrow mechanism to ensure both the sender and receiver are protected, ensuring a stable and worry-free process.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
