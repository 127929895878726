import React from "react";

import CheaperImage from "../assets/images/Cheaper.svg";
import FasterImage from "../assets/images/Faster.svg";
import SecureImage from "../assets/images/Secure.svg";

import styles from "../styles/who_can_benefit.module.css";

export default function WhoCanBenefit() {
  return (
    <section id="who-can-benefit" className={styles["why__use_section"]}>
      <div className={styles["container"]}>
        <div className={styles["why__use_container"]}>
          <h1 className={styles["why__use_title"]}>Who Can Benefit?</h1>
          <div className={styles["transaction__boxes"]}>
            <div className={styles["transacton__box"]}>
              <img src={CheaperImage} alt="Cheaper" width={62} height={62} />
              <h1>⁠International Businesses</h1>
              <p>Expand your global reach and unlock new business opportunities by seamlessly transacting with partners and customers across borders.</p>
            </div>
            <div className={styles["transacton__box"]}>
              <img src={FasterImage} alt="Fees" width={62} height={62} />
              <h1>Large Enterprises</h1>
              <p>Streamline your international payment operations and reduce costs by leveraging our efficient and cost-effective cross-border payments platform.</p>
            </div>
            <div className={styles["transacton__box"]}>
              <img
                src={SecureImage}
                alt="Secure"
                width={62}
                height={62}
              />
              <h1>Financial Institutions</h1>
              <p>
              Enhance your offerings and stay competitive by providing your clients with access to our cutting-edge CBDC-powered payment solution.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
