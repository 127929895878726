import React from "react";

import styles from "../styles/home.module.css";
import NavbarComponent from "../components/Navbar";
import LandingComponent from "../components/Landing";
import SecurityComponent from "../components/Security";
import BussinessComponents from "../components/Bussiness";
import WhyCBDCXComponent from "../components/WhyCBDCX";
import SupportersComponent from "../components/Supporters";
import ContactSection from "../components/ContactUs";
import FooterComponent from "../components/Footer";
import TransactionComponent from "../components/Transaction";
import WhoWeAreComponent from "../components/WhoWeAre";
import WhyUseCBDCXComponent from "../components/WhyUse";
import SecureComponent from "../components/Secure";
import OnAppComponent from "../components/OneApp";
import WhoCanBenefit from "../components/Benefits";
import OurTeamComponents from "../components/OurTeam";

export default function HomeLayout() {
  return (
    <div className={styles["home__container"]}>
      <div className={styles["home__content_container"]}>
        <div className={styles["home__background"]}>
          <NavbarComponent />
          <LandingComponent />
        </div>
        <WhoWeAreComponent />
        <WhyCBDCXComponent />
        <OnAppComponent />
        <TransactionComponent />
        <WhoCanBenefit />
        <OurTeamComponents />
        <WhyUseCBDCXComponent />
        <SecureComponent />
        {/* <SupportersComponent /> */}
        <ContactSection />
        <FooterComponent />
      </div>
    </div>
  );
}
