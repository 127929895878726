import React from "react";

import styles from "../styles/who_we_are.module.css";

export default function WhoWeAreComponent() {
  const stats = [
    {
      title: "Transactions on test-net",
      value: "USD 1+ MN",
    },
    {
      title: "Countries exploring CBDC",
      value: "160+",
    },
    {
      title: "People in developing countries",
      value: "6+ Billion",
    },
  ];

  return (
    <section id="who-we-are" className={styles["who__we_are_section"]}>
      <div className={styles["container"]}>
        <div className={styles["who__we_are_content"]}>
          <h1 className={styles["who__we_are_title"]}>Who We Are</h1>
          <p className={styles["who__we_are_description"]}>
          We are pioneering the use of CBDCs for cross-border payments, leveraging the latest advancements in <br />blockchain technology and financial infrastructure.
          </p>
          <div className={styles["who__we_are_stats"]}>
            {stats.map((stat, index) => {
              return <div className={styles["stat__box"]} key={index}>
                <h1>{stat.value}</h1>
                <p>{stat.title}</p>
              </div>;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
