import React from "react";

import TransactionImage from "../assets/images/transaction.png";
import ExchangeImage from "../assets/images/exchange.svg";
import FeeImage from "../assets/images/fees.svg";
import ExtraChargesImage from "../assets/images/extra.svg";

import styles from "../styles/transaction.module.css";

export default function TransactionComponent() {
  return (
    <section className={styles["transaction__section"]}>
      <div className={styles["container"]}>
        <div className={styles["transaction__details_content"]}>
          <div className={styles["transaction__image"]}>
            <img src={TransactionImage} alt="transaction__image" />
          </div>
          <h1 className={styles["transaction__title"]}>
            Sign Up. Transact. Settle.
          </h1>
          {/* <p className={styles["transaction__description"]}>
            What you see is what you pay. Our rates are fair, our fees are low
            and above all we are transparent, so you see what you are paying for
            with every transaction.
          </p> */}
          <div className={styles["transaction__boxes"]}>
            <div className={styles["transacton__box"]}>
              <img
                src={FeeImage}
                alt="Exchange rate"
                width={82}
                height={82}
              />
              <h1>Sign Up</h1>
              <p>
                Create an account on CBDCX and complete the verification
                process.
              </p>
            </div>
            <div className={styles["transacton__box"]}>
              <img src={ExchangeImage} alt="Exchange rate" width={82} height={82} />
              <h1>Initiate Transaction</h1>
              <p>
                Enter the details of your cross-border payment, to be paid or
                received.
              </p>
            </div>
            <div className={styles["transacton__box"]}>
              <img
                src={ExtraChargesImage}
                alt="No extra Charges"
                width={82}
                height={82}
              />
              <h1>Instant Settlement</h1>
              <p>
                The transaction is settled instantly, and funds
                are made available to the recipient.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
